<template>
  <b-modal
      v-model="showUser"
      centered
      title="报名费减免审核"
      size="lg"
      title-class="font-18"
      hide-footer
  >
    <yzShowTable :form="form" :bounds="bounds" style="margin-bottom: 10px"></yzShowTable>
    <div class="d-flex check-distri mb-3">
      <label>审核金额：</label>
      <div class="col-sm-10 p-0">
        <input
            v-model="commitForm.sqjmje"
            type="number"
            oninput="if(value.length>5) value=value.slice(0,5)"
            placeholder="审核金额"
            class="form-control w-100 h30"
        />
      </div>
    </div>
    <div class="d-flex check-distri mb-3">
      <label>审核说明：</label>
      <div class="col-sm-10 p-0">
        <textarea
            v-model="commitForm.shsm"
            type="text"
            oninput="if(value.length>200) value=value.slice(0,200)"
            placeholder="审核说明"
            class="form-control w-100"
        />
      </div>
    </div>
    <div class="mt-3 text-center">
      <button type="button" class="btn btn-info h30 w-md mr-3" @click="handle(1)">
        同意
      </button>
      <button type="button" class="btn btn-danger h30 w-md mr-3"  @click="handle(2)">
        不同意
      </button>
      <button
          type="button"
          class="btn btn-secondary h30 w-md"
          @click="showUser = false"
      >
        关闭窗口
      </button>
    </div>
  </b-modal>
</template>

<script>
import yzShowTable from "@/components/form/yzShowTable";
import {getRegisterFeeApply, handleRegisterFeeApply} from "@/api/admin/apply/apply";
import {fileSizeCover} from "@/utils/file";
export default {
  components:{
    yzShowTable,
  },
  data() {
    return {
      form: {},
      commitForm: {},
      showUser: false,
      bounds: []
    };
  },
  methods:{
    show(sid){
      getRegisterFeeApply(sid).then(res=>{
        if(res.status){
          this.form = res.data
          this.form.jmje = this.form.jmje / 100
          this.bounds = [{
            label: "考试年份",
            bound: "ksnf",
            width: "50"
          },
            {
              label: "考试名称",
              bound: "ksmc",
              width: "50"
            },
            {
              label: "考生姓名",
              bound: "xm",
              width: "50"
            },
            {
              label: "报名费用",
              bound: "yjfje",
              type: "money",
              width: "50"
            },
            {
              label: "申请减免",
              bound: "sqjmje",
              type: "money",
              width: "50"
            },
            {
              label: "申请原因",
              bound: "jmyy",
              width: "100"
            },
            {
              label: "原因描述",
              bound: "jmxs",
              width: "100"
            },
            {
              label: "支撑材料",
              bound: "jmfj",
              type: "link",
              linkDes: this.form.jmfjmc+" / "+fileSizeCover(this.form.jmfjdx),
              width: "100"
            },
            {
              label: "申请时间",
              bound: "createTime",
              type: "datetime",
              width: "100"
            },
            {
              label: "申请状态",
              bound: "shzt",
              type: Boolean,
              width: "100"
            },
            {
              label: "审核金额",
              bound: "jmje",
              type: "money",
              width: "100"
            },
            {
              label: "审核时间",
              bound: "shsj",
              type: "datetime",
              width: "100"
            },
          ]
          this.showUser = true
        }
      })
    },
    hide(){
      this.showUser = false
    },
    handle(shzt){
      this.commitForm.shzt = shzt
      let form = JSON.parse(JSON.stringify(this.commitForm))
      form.jmje = form.jmje * 100
      handleRegisterFeeApply(this.form.sid, form).then(res=>{
        if(res.status){
          this.$message({
            type: "success",
            message: "审核成功!",
          });
          this.$emit("success");
        }
      })
    }
  },
  mounted() {
  },
};
</script>

<style>
.guding-img {
  width:20%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #DFDFDF;
  border-right: 1px solid #DFDFDF;
}

.no-border-top {
  border-top: unset !important;
}
.no-border-bottom {
  border-bottom: unset !important;
}
</style>
