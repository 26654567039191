<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/enroll-form.css";
</style>
<style type="text/css" scoped>
.modal-title /deep/ {
  text-align: center !important;
  width: 100% !important;
  padding: auto !important;
}

/deep/.el-select.blueBorder .el-input__inner {
  border-color: rgb(23, 118, 210);
}
</style>
<script>
import Layout from "@/views/layouts/main";
import CheckHeader from "@/components/check-header";
import changeExam from "@/views/pages/admin/examination/change-exam";
import YzSearch from "@/components/form/yzSearch.vue";
import {
  getNowExam,
    getExamDetails
} from "@/api/admin/exam/examRecord.js"
import handleModal from "@/views/pages/admin/credit-review/handleModal";
import {deleteRegisterFeeApply, getRegisterFeeApplyListPage} from "@/api/admin/apply/apply";
import handleStatus from "@/components/table/handleStatus";
import UserInfoCheck from '@/views/pages/user/components/user-Info-check.vue'
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    CheckHeader,
    changeExam,
    YzSearch,
    handleModal,
    handleStatus,
    UserInfoCheck,
  },
  data() {
    return {
      title: "收费减免审核  / ",
      subTitle: "切换报名",
      checDiv: false,
      items: [
        {
          text: "用户首页",
          href: "/admin",
        },
        {
          text: "收费减免",
          active: true,
        },
      ],
      pageData: {
        pageNum: 1,
        pageSize: 10,
        shzt:""
      },
      dataList: [],
      ksInfo: {},
      search: [],
      examInfo:{},
      shzt: [ {
        name: "审核状态",
        value: ""
      },
          {
          name: "通过",
          value: "1"
      },
        {
            name: "未通过",
            value: "2"
        }, {
            name: "待审核",
            value: "0"
        }]


    };
  },
  methods: {
    searchClick(){
      this.pageData.pageNum=1
      this.getList()
    },
    //  获取单位列表
    getList() {
      this.pageData.ksbmbh = this.ksInfo.ksbmbh;
      getRegisterFeeApplyListPage(this.pageData).then((res) => {
        if (res.status) {
          this.dataList = res.data;
          this.pageData.total = res.total;
          this.pageData.pageNum = res.pageNum;
          this.pageData.pageSize= res.pageSize;
        }
      });
    },
    // 删除
    deleteItem(sid) {
      this.$confirm(`是否确认删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteRegisterFeeApply(sid).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          }
        });
      });
    },
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.pageNum = val;
      this.getList();
    },
    //获取切换的考试
    getLastExam() {
      getNowExam().then(res => {
        if (res.status) {
          this.ksInfo = res.data
          this.getList()
            this.getExamInfo()

        }
      })
    },
      getExamInfo(){
          getExamDetails(this.pageData.ksbmbh).then((res) => {
              if (res.status) {
                  this.examInfo = res.data;
              }
          })
      }


  },

  mounted() {
    this.getLastExam()
    this.search = [
      {
        title: "姓名/身份证号",
        bound: "keyword",
        type: "input",
      },
    ];
  },
};
</script>

<template>
  <Layout>
    <CheckHeader
        :title="title"
        :title2="ksInfo.ksmc"
        :subTitle="subTitle"
        :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <div class="check-table-top">
              <div class="flexList" style="flex: 1">
                <yz-search
                    :data="search"
                    v-model="pageData"
                    @seachList="searchClick"
                >
                    <el-select class="ml-2 d-inline-block" style="max-width: 150px" placeholder="请选择审核状态" v-model="pageData.shzt" size="small">
                        <el-option v-for="(item, i) in shzt" :label="item.name" :value="item.value" :key="i">
                        </el-option>
                    </el-select>

                </yz-search>
                  <div v-if="examInfo.jmshkssj||examInfo.jmshjssj" class="flexList">
                      <div class="mr-2" ><span>减免审核开始时间：</span><span class="blue-font">{{examInfo.jmshkssj}}</span></div>
                      <div > <span>减免结束开始时间：</span><span class="blue-font">{{examInfo.jmshjssj}}</span></div></div>
                  <div v-else class="text-danger">暂未设置减免审核时间，请到考试报名管理处设置</div>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab">
                  <i class="iconfont icon-antOutline-file-excel mr-2"></i>excel
                </div>
                <div class="border-blue export-tab">
                  <i class="iconfont icon-data mr-2"></i>dbf
                </div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover">
                <thead class="thead-light">
                <tr>
                  <th style="width: 4%">序号</th>
<!--                  <th style="width: 15%">考试名称</th>-->
                  <th style="width: 5%">姓名</th>
                  <th style="width: 12%">身份证件号</th>
                  <th style="width: 5%">应缴费用</th>
                  <th style="width: 5%">减免金额</th>
                  <th >申请原因</th>
                  <th style="width: 10%">申请时间</th>
                  <th style="width: 6%">审核状态</th>
                  <th style="width: 5%">审核人</th>
                  <th style="width: 10%">处理时间</th>
                  <th style="width: 3%">操作</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in dataList" :key="i">
                  <td scope="row">{{ (pageData.pageNum - 1) * pageData.pageSize +  i + 1 }}</td>
<!--                  <td>{{ item.ksmc }}</td>-->
<!--                  <td><el-button type="text" class="font-blue" @click="$yzUserInfo().show(item.sfzjh)">{{ item.xm }}</el-button></td>-->
                  <td>{{ item.xm }}</td>
                  <td>{{ item.sfzjh }}</td>
                  <td>{{ item.yjfje/100 }} 元</td>
                  <td>{{ item.sqjmje/100 }} 元</td>
                  <td>{{ item.jmyy }}</td>
                  <td>{{ formatDateDetails(item.createTime) }}</td>
                  <td><handleStatus :shzt="item.shzt"></handleStatus></td>
                  <td>{{ item.shrxm }}</td>
                  <td>{{ formatDateDetails(item.shsj) }}</td>
                  <td class="tab-icon">
<!--                      <i-->
<!--                          class="iconfont icon-edit-two text-dark align-middle mr-1" @click="$refs.handleModal.show(item.sid)"-->
<!--                      ></i>-->
                      <i
                          class="iconfont icon-edit-two text-dark align-middle mr-1" @click="$refs.userInfoCheck.show(item.bmh, item.ksbmbh, item.ksmc, 'breaks', true)"
                      ></i>
                    <!-- <i
                        @click="deleteItem(item.sid)"
                        class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                    ></i> -->
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination
                  @size-change="handleSizeChange"
                  background
                  @current-change="handleCurrentChange"
                  :current-page.sync="pageData.pageNum"
                  :page-size="pageData.pageSize"
                  layout="total, sizes,jumper, prev, pager, next"
                  :total="pageData.total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <changeExam></changeExam>
    <handleModal ref="handleModal" @success="$refs.handleModal.hide();getList()"></handleModal>
    <UserInfoCheck ref="userInfoCheck" @success="$emit('success');getList()"></UserInfoCheck>
  </Layout>
</template>
